import { NextSeo } from "next-seo";
import { VideoDemos } from "@/components/shared/VideoDemos";
import dynamic from "next/dynamic";
import { useModalContext } from "@/lib/contexts/ModalContext";
import { useEffect } from "react";
const Hero = dynamic(() => import("@/components/home/Hero"));
const HowItWorks = dynamic(() => import("@/components/home/HowItWorks"));
const Features = dynamic(() => import("@/components/home/Features"));
const Testimonials = dynamic(() => import("@/components/shared/Testimonials"));
const FAQ = dynamic(() => import("@/components/home/FAQ"));
const CTA = dynamic(() => import("@/components/home/CTA"));
const Footer = dynamic(() => import("@/components/shared/Footer"));

export default function Home() {
  const { openBfcmModal } = useModalContext();

  useEffect(() => {
    const shownBFCM = Boolean(localStorage.getItem("shown_bfcm"));
    if (!shownBFCM) {
      (async () => {
        await new Promise((resolve) => setTimeout(resolve, 1200));
        localStorage.setItem("shown_bfcm", "true");
        openBfcmModal();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white">
      <NextSeo
        title="Crayo AI | Viral clips in seconds."
        description="Your gateway to creating viral TikTok clips in seconds! Harness the power of AI to turn your ideas into captivating videos effortlessly."
      />
      <Hero />
      <div className="flex flex-col items-center justify-between space-y-12 bg-gradient-to-b from-white to-[#F0F7FF] pt-28 text-center">
        <div className="-mb-8 flex w-full flex-col px-8">
          <h3 className="text-[36px] leading-[120%] md:text-[48px]">
            The software behind faceless virality
          </h3>
        </div>
        <VideoDemos />
        <Testimonials index={1} />
      </div>
      <Features />
      <Testimonials index={0} />
      <HowItWorks />
      <FAQ />
      <CTA />
      <Footer />
    </div>
  );
}
